<template>
  <page-layout>
    <template slot="side">
      <h1 class="title is-2 has-text-weight-black">{{ post.title }}</h1>
      <hr>
      <p class="subtitle">
        <span>{{ post.date }} &middot; </span>
        <span class="has-text-secondary">{{ post.minutes }} MIN READ</span>
      </p>
      <social-share :title="post.title"></social-share>
    </template>

    <template slot="mobile">
      <h1 class="title is-2 has-text-weight-black">{{ post.title }}</h1>
      <p class="subtitle">
        <span>{{ post.date }} &middot; </span>
        <span class="has-text-secondary">{{ post.minutes }} MIN READ</span>
      </p>
      <social-share :title="post.title"></social-share>
      <hr>
    </template>

    <template slot="main">
      <article class="content" v-html="post.body"></article>

      <hr class="is-large">

      <div class="columns blog-footer is-mobile is-centered has-text-centered content">
        <div class="column is-5-desktop is-7-tablet is-10-mobile">
          <p>
            <!-- We show add to browser to desktop users with extension not installed. -->
            <add-to-browser
              medium
              class="has-max-width-100per"
              position="ArticleMobileView"
            >Add Pouch for Free</add-to-browser>

            <!-- We show a link to the landing page to mobile users all the time. -->
            <router-link :to="{ name: 'home' }"
                         class="button is-primary is-large has-text-weight-semibold has-max-width-100per"
                         v-hidden-extension-fit>
              Add Pouch for Free
            </router-link>
          </p>
          <p>
            <img v-lazy="`$/images/chrome-webstore-rating.png`" alt="Chromestore 4.7 stars">
          </p>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex'
import { applySeo } from '@/seo'
import SocialShare from './components/SocialShare'
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  components: { AddToBrowser, SocialShare },

  computed: mapState({
    post: state => state.blog.currentPost
  }),

  mounted () {
    applySeo({
      title: `${this.post.title} | Pouch Blog | Pouch`,
      meta: {
        description: 'Pouch Blog. All the Best Money-Saving Hints and Tips from the Pouch Team.',
        image: this.post.image
      }
    })
  }
}
</script>
