<template>
  <aside class="social">
    <div class="box" @click="sharePost('twitter')">
      <span class="icon is-medium"><i class="fab fa-fw fa-lg fa-twitter" style="color: #0084b4"></i></span>
    </div>
    <div class="box" @click="sharePost('facebook')">
      <span class="icon is-medium"><i class="fab fa-fw fa-lg fa-facebook-f" style="color: #3b5998"></i></span>
    </div>
    <div class="box" @click="sharePost('whatsapp')">
      <span class="icon is-medium"><i class="fab fa-fw fa-lg fa-whatsapp" style="color: #34af23"></i></span>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    title: String
  },

  data: () => ({
    links: {
      twitter: {
        base: 'https://twitter.com/intent/tweet',
        query: {
          text: '"{title}" by @joinpouch {url}?utm_source=twitter&utm_medium=social&utm_campaign={title_enc}'
        }
      },
      facebook: {
        base: 'https://www.facebook.com/sharer/sharer.php',
        query: {
          u: '{url}?utm_source=facebook&utm_medium=social&utm_campaign={title}'
        }
      },
      whatsapp: {
        base: 'https://api.whatsapp.com/send',
        query: {
          text: '"{title}" by @joinpouch {url}?utm_source=whatsapp&utm_medium=social&utm_campaign={title_enc}'
        }
      }
    }
  }),

  methods: {
    sharePost (media) {
      const link = this.links[media]
      const url = new URL(link.base)

      Object.keys(link.query).forEach((key) => {
        const value = link.query[key]
          .replace(/\{url\}/g, location.href)
          .replace(/\{title\}/g, this.title)
          .replace(/\{title_enc\}/g, encodeURIComponent(this.title))

        url.searchParams.set(key, value)
      })

      open(url.toString().replace(/\+/g, '%20'), '', 'resizable=yes,width=700,height=600')
    }
  }
}
</script>
